.profile-Form {
    overflow-y: scroll;
    padding: 10px 20px;
}

.profile-Form::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.cptn .heading {
    font-weight: 500;
    font-size: 40px;
    line-height: 50px;
    color: #1c4980;
    margin-bottom: 10px;
}
.heading_mobile {
    display: none;
}
.cptn p{
    font-size: 17px;
    line-height: 140%;
    margin-bottom: 0;
}
.heading_yellow {
    color: #fdb902;
    font-weight: inherit;
}
.heading_line {
    font-weight: 400;
}
header[_ngcontent-serverApp-c332]   p[_ngcontent-serverApp-c332] {
    font-size: 20px;
    line-height: 140%;
    color: #1c4980;
    margin-bottom: 0;
}
.numBox1 {
    border-right: 1px solid #DADCE0;
    padding: 20px 20px 20px 10px;
    display: flex;
    align-items: center;
    color: #4c3b9e;
}
.numberBox .numBox1:nth-child(2) {
    color: #D86D00;
}
.numberBox .numBox1:last-child {
    color: #0073e6;
    border-right: 0;
}
.numberBox {
    border: 1px solid #DADCE0;
    display: inline-flex;
    border-radius: 8px;
    margin-top: 50px;
    background: #ffff;
}
.numBox1 img {
    width: auto;
    height: auto;
}
.numBox1 strong {
    font-weight: 500;
    font-size: 36px;
    line-height: 100%;
    padding-left: 10px;
}
.numberBox span {
    font-size: 20px;
    font-weight: 400;
    display: block;
    margin-top: 5px;
    line-height: 100%;
}
.buttonApply {
    width: 200px;
    height: 50px;
    background: #0073E6;
    border: 2px solid #0073E6;
    border-radius: 8px;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: .3s;
    margin-top: 30px;
}
.buttonApply:hover {
    background: #fff;
    border: 2px solid #0073E6;
    transition: .3s;
    color: #0073E6;
}
.imgBox img {
    width: 60%;
    display: flex;
    margin: 0 auto;
}

.job-post-step .item strong {
    padding-left: 30px;
    color: #1c4980;
    font-weight: 400;
    font-size: 21px;
    line-height: 29px;
}

.job-post-step .item span {
    display: block;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    margin-top: 10px;
}
.left_content img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
}
.left_content h6 {
    color: #fff;
}
.left_content h6 b {
    font-weight: 500;
    font-size: 28px;
    line-height: 42px;
    color: #fff;
}
.left_content {
    background: #0073e6;
    border-radius: 40px;
    padding: 30px 30px 0;
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    height: 100%;
}
.why {
    padding-left: 100px;
}
.job-post-step .item {
    display: flex;
    margin-bottom: 30px;
}
.item .img-fluid {
    max-width: 100%;
    height: 80px;
    width: 80px;
    background-color: #ff7a00;
    border-radius: 25px;
    padding: 22px;
    box-shadow: 0px 0px 15px -5px #ff7a00a1;
}
h4 {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    color: #1c4980;
    margin-bottom: 50px;
}

.become_addition .heading_box h4 {
    margin-bottom: 0px;
}
h4 span {
    color: #fdb902;
}

.colored_boxes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: -15px;
}
.colored_boxes   .colorBox:nth-child(1) {
    width: calc(35% - 30px);
}
.colored_boxes   .colorBox:nth-child(2) {
    background: #ADD6FF;
    width: calc(65% - 30px);
}
.colored_boxes   .colorBox:nth-child(3) {
    background: #FFC7BC;
    width: calc(55% - 30px);
}
.colored_boxes   .colorBox:nth-child(4) {
    background: #FFE8BC;
    width: calc(45% - 30px);
}
.colored_boxes   .colorBox {
    background: #C1B2FF;
    display: flex;
    align-items: center;
    position: relative;
    margin: 15px;
    min-height: 204px;
    border-radius: 20px;
    padding: 0 30px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
}
.colorBox span {
    z-index: 1;
    position: relative;
    color: #fff;
    margin-bottom: 15px;
}
.colorBox:nth-child(1) span:after {
    content: '';
    background: #804bde;
    width: 50px;
    height: 50px;
    position: absolute;
    left: -20px;
    top: -15px;
    border-radius: 50%;
    z-index: -1;
    color: #fff;
}
.colorBox:nth-child(2) span:after {
    content: '';
    background: #0073E6;
    width: 50px;
    height: 50px;
    position: absolute;
    left: -20px;
    top: -15px;
    border-radius: 50%;
    z-index: -1;
    color: #fff;
}
.colorBox:nth-child(3) span:after {
    content: '';
    background: #ff7a00;
    width: 50px;
    height: 50px;
    position: absolute;
    left: -20px;
    top: -15px;
    border-radius: 50%;
    z-index: -1;
    color: #fff;
}
.colorBox:nth-child(4) span:after {
    content: '';
    background: #fdb902;
    width: 50px;
    height: 50px;
    position: absolute;
    left: -20px;
    top: -15px;
    border-radius: 50%;
    z-index: -1;
    color: #fff;
}

.colorBox p {
    z-index: 1;
}
.colorBox:after {
    content: '';
    position: absolute;
    background: transparent;
    width: 130px;
    height: 130px;
    border: 23px solid #ffffff;
    border-radius: 30px;
    transform: rotate(132deg);
    opacity: 0.6;
    top: -33px;
    right: 7px;
    z-index: 0;
}
.mentor_box_img img {
    border-radius: 20px;
    width: 600px;
    height: 440px;
    object-fit: cover;
    display: flex;
    margin: 0 auto;
}
button.accordion-button:first-child {
    padding-top: 0;
}

button.accordion-button {
    border-bottom: 1px solid #FFBA00;
    padding: 25px 0;
    position: relative;
    z-index: 9;
    font-size: 18px;
}


#accordionMentorship .accordion-item .accordion-button {
    font-size: 16px;
    padding: 25px 0px;
    font-weight: 400;
}
#accordionMentorship .accordion-item .accordion-button::after {
    flex-shrink: 0;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: 1rem;
    transition: var(--bs-accordion-btn-icon-transition);
}
.contact_box {
    background-color: #ff7a0029;
    padding: 40px;
    width: 85%;
    margin: 0 auto;
    border-radius: 15px;
    position: relative;
    overflow: hidden;
}

.contact_box:after {
    content: "";
    position: absolute;
    margin: auto;
    background-image: url('./../../assets/images/send-mail.png');
    width: 100px;
    height: 200px;
    right: 17px;
    top: -4px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(-10deg);
}

/*mentorForm Page css starts*/

.heading_form {
    background-color: #0073e629;
    padding: 20px;
    border-radius: 16px 16px 0px 0px;
    position: relative;
    overflow: hidden;
}
.heading_form:after {
    content: '';
    position: absolute;
    background-image: url('./../../assets/images/up2.png');
    width: 150px;
    height: 150px;
    opacity: 0.6;
    right: 0;
    top: 0;
    z-index: 1;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.heading_form h5 {
    color: #212529;
    margin-bottom: 10px;
}
hr.hori_line {
    width: 30%;
    border: 3px solid #0073e6;
    opacity: 1;
}
form#careerForm .form-label {
    font-size: 14px;
    font-weight: 400;
    color: #05254D;
    margin-bottom: 0px;
    display: block;
}
form#careerForm .form-group {
    display: flex;
    flex-wrap: wrap;
}
form#careerForm .form-group label.form-label:hover, form#careerForm .form-group .active, form.profile-Form .form-group label.form-label:hover, form.profile-Form .form-group .active {
    background: #e5f1fc59 !important;
}
form#careerForm .form-group label.form-label, form.profile-Form .form-group label.form-label {
    margin-bottom: .5rem;
    border: 1px solid #DADCE0;
    height: auto;
    min-height: 84px;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    flex: 1 0 calc(30% - 10px);
    margin: 5px;
    color: #05254D;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    padding: 15px;
    transition: .3s;
    cursor: pointer;
}
.check_boxes {
    width: 50px;
    height: 50px;
    background: #E5F1FC;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
}
.form-group.form_group {
    display: flex;
    flex-wrap: nowrap !important;
    border: 1px solid #dee2e6;
}

button#js_trigger-dropdown {
    border: 0px;
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #fff;
}
img#js_selected-flag {
    width: 20px;
    margin-right: 5px;
}
span.red_star {
    color: red;
}

@media only screen and (min-width : 320px) and (max-width : 767px) {
    .banner_row {
        flex-direction: column-reverse;
    }
    .imgBox {
        background-position: bottom center !important;
    }
    .btns .buttonApply {
        width: 100%;
        margin-top: 30px;
    }
    .heading_mobile {
        display: block;
        color: #1c4980;
        margin-bottom: 20px;
        font-size: 30px;
        position: relative;
    }
    .heading_mobile::after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 0;
        background: #f2c034;
        width: 35%;
        height: 2px;
    }
    .cptn .heading {
        display: none;
    }
    .numberBox {
        display: block;
    }
    .numBox1 {
        border-bottom: 1px solid #DADCE0;
        border-right: 0px !important;
    }
    .numberBox .numBox1:last-child {
        border-bottom: 0px;
    }
    .numberBox span {
        font-size: 17px;
        display: contents;
    }
    .numBox1 strong {
        font-size: 26px;
    }
    h4 {
        font-size: 22px;
        margin-bottom: 20px;
        line-height: 30px;
    }
    .why {
        padding: 10px;
        border: 1px solid #DDE5EA;
        margin-top: 30px;
        border-radius: 15px;
    }
    .why .item img {
        width: 60px;
        height: 60px;
        border-radius: 15px;
        padding: 15px;
    }
    .item strong {
        font-weight: 500;
        font-size: 15px;
        padding-left: 18px;
        line-height: 22px;
    }
    .item span {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
    }
    .buttonApply {
        width: 100%;
        margin-top: 0px; 
    }
    section.why-careers {
        padding-bottom: 0px !important;
        padding-top: 0px !important;
    }
    section.how_canbe_mentor {
        padding-top: 0px !important;
    }
    .colored_boxes {
        margin: 0px;
    }
    .colored_boxes   .colorBox:nth-child(1) {
        width: 100%;
        margin: 10px 0px;
    }
    .colored_boxes   .colorBox:nth-child(2) {
        width: 100%;
        margin: 10px 0px;
    }
    .colored_boxes   .colorBox:nth-child(3) {
        width: 100%;
        margin: 10px 0px;
    }
    .colored_boxes   .colorBox:nth-child(4) {
        width: 100%;
        margin: 10px 0px;
    }
    .left_content {
        display: none;
    }
    .contact_box {
        padding: 20px;
        width: 100%;
    }
    .contact_box:after {
        width: 65px;
        right: 17px;
        top: 44px;
    }
    .imgBox img {
        width: 100%;
    }
    form#careerForm .form-group label.form-label {
        flex-basis: calc(25% - 5px);
        margin: 2px;
        padding: 10px;
    }
}
@media only screen and (min-width : 768px) and (max-width : 1279px) {
    .btns .buttonApply {
        margin-top: 30px;
    }
    .heading_mobile {
        display: none;
    }
    .cptn p {
        position: relative;
        width: 360px;
    }
    .heading_mobile::after {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 0;
        background: #f2c034;
        width: 35%;
        height: 2px;
    }
    .imgBox {
        background-position: left !important;
        background-size: cover !important;
    }
    
    .numberBox {
       position: relative;
       background-color: #fff;
    }
    
    .numberBox span {
        font-size: 17px;
        display: contents;
    }
    .numBox1 strong {
        font-size: 26px;
    }
    h4 {
        font-size: 28px;
        margin-bottom: 20px;
        line-height: 30px;
    }
    .why {
        padding: 10px;
        border: 1px solid #DDE5EA;
        border-radius: 15px;
    }
    .item {
        margin-bottom: 10px;
    }
    .why .item img {
        width: 60px;
        height: 60px;
        border-radius: 15px;
        padding: 15px;
    }
    .item strong {
        font-weight: 500;
        font-size: 15px;
        padding-left: 18px;
        line-height: 22px;
    }
    .item span {
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
    }
    section.why-careers {
        padding-bottom: 0px !important;
    }

    .buttonApply {
        margin-top: 0px;
    }
    section.how_canbe_mentor {
        padding-top: 0px !important;
    }
    .colored_boxes {
        margin: 0px;
    }
    .colored_boxes   .colorBox:nth-child(1) {
        width: 100%;
        margin: 10px 0px;
    }
    .colored_boxes   .colorBox:nth-child(2) {
        width: 100%;
        margin: 10px 0px;
    }
    .colored_boxes   .colorBox:nth-child(3) {
        width: 100%;
        margin: 10px 0px;
    }
    .colored_boxes   .colorBox:nth-child(4) {
        width: 100%;
        margin: 10px 0px;
    }
    .mentor_box_img {
        display: none;
    }
    
    .contact_box {
        padding: 20px;
        width: 100%;
    }
    .contact_box:after {
        width: 65px;
        right: 17px;
        top: 44px;
    }
    .imgBox img {
        width: 100%;
    }
    form#careerForm .form-group label.form-label {
        flex-basis: calc(25% - 5px);
        margin: 2px;
        padding: 10px;
    }
}

/*mentorForm Page css ends*/

/* testimonials css start */

.post-slide{
    background: #fff;
    margin: 20px 15px 20px;
    border-radius: 15px;
    padding-top: 1px;
    box-shadow: 0px 14px 22px -9px #bbcbd8;
}

.post-slide .post-img{
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: -12px 15px 8px 15px;
    margin-left: -10px;
    display: flex;
}
.post-slide .post-img img{
    width: 10%;
    height: auto;
    transform: scale(1,1);
    transition:transform 0.2s linear;
}
.post-slide:hover .post-img img{
    transform: scale(1.1,1.1);
}
.post-slide .over-layer{
    width:100%;
    height:100%;
    position: absolute;
    top:0;
    left:0;
    opacity:0;
    background: linear-gradient(-45deg, rgba(6,190,244,0.75) 0%, rgba(45,112,253,0.6) 100%);
    transition:all 0.50s linear;
}
.post-slide:hover .over-layer{
    opacity:1;
    text-decoration:none;
}
.post-slide .over-layer i{
    position: relative;
    top:45%;
    text-align:center;
    display: block;
    color:#fff;
    font-size:25px;
}
.post-slide .post-content{
    background:#fff;
    padding: 2px 20px 40px;
    border-radius: 15px;
}
.post-slide .post-title a{
    font-size:15px;
    font-weight:bold;
    color:#333;
    display: inline-block;
    text-transform:uppercase;
    transition: all 0.3s ease 0s;
}

.post-slide .post-description{
    line-height:24px;
    color:#808080;
    margin-bottom:25px;
}
.post-slide .post-date{
    color:#a9a9a9;
    font-size: 14px;
}
.post-slide .post-date i{
    font-size:20px;
    margin-right:8px;
    color: #CFDACE;
}
.post-slide .read-more{
    padding: 7px 20px;
    float: right;
    font-size: 12px;
    background: #2196F3;
    color: #ffffff;
    box-shadow: 0px 10px 20px -10px #1376c5;
    border-radius: 25px;
    text-transform: uppercase;
}
.post-slide .read-more:hover{
    background: #3498db;
    text-decoration:none;
    color:#fff;
}
.owl-controls .owl-buttons{
    text-align:center;
    margin-top:20px;
}
.owl-controls .owl-buttons .owl-prev{
    background: #fff;
    position: absolute;
    top:-13%;
    left:15px;
    padding: 0 18px 0 15px;
    border-radius: 50px;
    box-shadow: 3px 14px 25px -10px #92b4d0;
}
.owl-controls .owl-buttons .owl-next{
    background: #fff;
    position: absolute;
    top:-13%;
    right: 15px;
    padding: 0 15px 0 18px;
    border-radius: 50px;
    box-shadow: -3px 14px 25px -10px #92b4d0;
}
.owl-controls .owl-buttons .owl-prev:after,
.owl-controls .owl-buttons .owl-next:after{
    content:"\f104";
    font-family: FontAwesome;
    color: #333;
    font-size:30px;
}
.owl-controls .owl-buttons .owl-next:after{
    content:"\f105";
}
@media only screen and (max-width:1280px) {
    .post-slide .post-content{
        padding: 0px 15px 25px 15px;
    }
}


.imgBox {
    background-image: url('./../../assets/images/exbg.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.active {
    display: block;
}

.selected-check {
    position: absolute;
    right: 19px;
    top: 15px;
}

.gender-box {
    position: relative;
}

span.sub-label {
    font-size: 13px;
    color: #000000b8;
    font-family: 'MADE TOMMY';
}

#careerForm input,  #careerForm .css-13cymwt-control , #careerForm .css-t3ipsp-control {
    margin-top: 10px;
}

#careerForm .social-input-field {
    margin-top: 10px;
    padding-left: 99px;
    padding: 10px 0px 10px 100px;
}

.social-input {
    position: relative;
}

.socical_icon_wrap {
    position: absolute;
    top: 0;
    left: 10px;
    height: 22px;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
}

.socical_icon_wrap1 {
    position: absolute;
    top: -37px;
    left: 10px;
    height: 22px;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
}

.socical_icon_wrap img.icon, .socical_icon_wrap1 img.icon  {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.ql-editor.ql-blank {
    height: 200px;
}

.avatar-wrapper{
    position: relative;
height: 200px;
width: 200px;
margin: 10px auto;
border-radius: 50%;
overflow: hidden;
box-shadow: 1px 1px 15px -5px black;
transition: all .3s ease;
display: flex;
align-items: center;
justify-content: center;
&:hover{
    transform: scale(1.05);
    cursor: pointer;
}
&:hover .profile-pic{
    opacity: .5;
}
.profile-pic {
height: 100%;
    width: 100%;
    transition: all .3s ease;
    &:after{
        font-family: FontAwesome;
        content: "\f007";
        top: 0; left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        font-size: 190px;
        background: #ecf0f1;
        color: #34495e;
        text-align: center;
    }
}
.upload-button {
    position: absolute;
    top: 0; left: 0;
    height: 100%;
    width: 100%;
    .fa-arrow-circle-up{
        position: absolute;
        font-size: 234px;
        top: -17px;
        left: 0;
        text-align: center;
        opacity: 0;
        transition: all .3s ease;
        color: #34495e;
    }
    &:hover .fa-arrow-circle-up{
        opacity: .9;
    }
}
}

.avatar-label{
    display: flex;
align-items: center;
justify-content: center;
}

/* icon upload */
.image-uploader {
    display: inline-block;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .upload-box:hover{
    border: 2px solid #ccc
  }
  
  .upload-box {
    width: 200px;
    height: 200px;
    border: 2px dashed #ccc;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .uploaded-image {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
  }
  
  .upload-box span {
    font-size: 16px;
    color: #666;
  }

  /* min -max input field */

  .salary-input {
    display: flex;
    align-items: center;
  }
  
  .salary-input select,
  .salary-input input {
    border: 1px solid #ccc; /* Add grey border */
    background: transparent;
    outline: none;
    font-size: 16px;
    padding: 8px;
  }
  
  
  .salary-input select {
    /* flex: 1;  */
    border-right: none; 
    width: 100px ;
    margin-top: 10px;

  }
  
  .salary-input input {
    flex: 1; 
  }
  
  .salary-input input:first-child {
    border-right: none; 
  }
  
  /* .salary-input input:last-child {
    border-left: none; 
  } */

  /* input date and time field */
  .dateandtime{
    width: 100%;
    border: 1px solid #ccc; 
    padding: 8px;
    border-radius: 5px;
    outline: none;
    color: #979292;
  }
  