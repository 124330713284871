/* Style for the custom select box */
.custom-select .css-1hb7zxy-IndicatorsContainer {
    padding: 2px;
  }

  /* Style for the select box */
  .select-box {
    appearance: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Style for the options container */
  .options-container {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: none;
  }

  /* Style for the individual options */
  .option {
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  /* Highlight the selected option */
  .option:hover {
    background-color: #f2f2f2;
  }