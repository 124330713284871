/* Loader.css */

.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 11112;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  .loader-container.visible {
    opacity: 1;
    pointer-events: auto;
  }
  
  .loader-container.hidden {
    opacity: 0;
    pointer-events: none;
  }
  
  .loader {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  