/* RightSideModal.css */

.right-side-modal {
  display: flex;
  position: fixed;
  top: 0;
  right: 0px; /* Initial position off-screen */
  height: 100%;
  transition: right 0.3s ease;
  z-index: 1111;
}

.right-side-modal.open {
  right: 0; /* Display on the right side of the screen */
  display: block;
}

.right-side-modal.close {
  display: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal-content {
  background-color: white;
  width: 100%;
  height: 100%;
  padding: 16px;
  box-sizing: border-box;
}

.close-btn {
  position: absolute;
  top: 16px;
  right: 16px;
  font-size: 20px;
  cursor: pointer;
  background: none;
  border: none;
}
